.note .note-content {
  overflow: hidden;
  padding: 5px 12px 5px 7px;
  font-style: italic;
  font-size: 10px;
  line-height: 1.2;
  color: #a0a0a0;
  border: 1px solid #c0c0c0;
  background-color: #ffffff;
  white-space: pre-wrap;
}
.note .note-content:before {
  content: "";
  position: absolute;
  top: -1px;
  right: -1px;
  width: 0;
  height: 0;
  border-top: 16px solid #ECF4FF;
  border-left: 16px solid transparent;
}
.note .note-content:after {
  content: "";
  position: absolute;
  top: -1px;
  right: -1px;
  width: 15px;
  height: 15px;
  border-top: 3px solid #ECF4FF;
  border-right: 3px solid #ECF4FF;
  border-left: 1px solid #c0c0c0;
  border-bottom: 1px solid #c0c0c0;
  background: linear-gradient(to top right, rgba(192, 192, 192, 0) 0%, rgba(192, 192, 192, 0) calc(50% - 1px), rgb(192, 192, 192) 50%, rgba(192, 192, 192, 0) calc(50% + 1px), rgba(192, 192, 192, 0) 100%);
}
.note .note-content .badge-container {
  position: absolute;
  top: -24px;
  right: calc(100% - 27px);
  width: 2.8rem;
  height: 2.8rem;
}
.note .note-content .badge-container .badge-checkbox {
  display: none;
  border: 1px solid #ddd;
  width: 1.9rem;
  height: 1.9rem;
  margin-top: 0.2rem;
  margin-left: 0.2rem;
}
.note .note-content .badge-container:hover .badge-checkbox {
  display: block;
}
.note .note-content .badge-container:hover .badge-icon {
  display: none;
}
.note .note-content .badge-container.selected .badge-checkbox {
  display: block;
}
.note .note-content .badge-container.selected .badge-icon {
  display: none;
}
.note .note-resizer {
  display: none;
  margin-top: -0.7rem;
  margin-left: -0.7rem;
  background: #ECF4FF;
}
.note .note-resizer i {
  transform: scaleX(-1);
  font-size: 0.72em;
  color: #a0a0a0;
  padding: 0.4em;
  background: #ECF4FF;
  border-radius: 50%;
}
.note:hover .note-resizer {
  display: block;
}

