#presentation-mode {
  width: auto;
  height: 100%;
  background: #ECF4FF;
  overflow-y: auto;
}
#presentation-mode #presentation-header {
  padding: 0.65rem 1.25rem 0.65rem 0.7rem;
  background: white;
  border-bottom: 1px solid #e7eaf3;
}
#presentation-mode #presentation-header #presentation-title .back-button {
  cursor: pointer;
  font-size: 1.15em;
  height: 2.3rem;
  color: #778798;
  padding-right: 0.4rem;
  padding-left: 0.6rem;
}
#presentation-mode #presentation-header #presentation-title .back-button:hover {
  color: #2080e5;
  font-weight: bold;
}
#presentation-mode #presentation-header #presentation-title .logo {
  cursor: pointer;
  margin-top: -0.17rem;
  margin-left: 0rem;
  height: 2.1rem;
}
#presentation-mode #presentation-header #presentation-title .logo-workix {
  cursor: pointer;
  margin-top: -0.17rem;
  width: 7rem;
  height: 2.1rem;
  margin-left: 0rem;
  background-image: url("../../../../public/theme/svg/logos/logo.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 7rem 2.1rem;
}
#presentation-mode #presentation-header #presentation-title .dropdown {
  margin-left: 0.1rem;
}
#presentation-mode #presentation-header #presentation-title .flow-name-container {
  height: 1.85rem;
}
#presentation-mode #presentation-header #presentation-title .flow-name-container .flow-name {
  color: #77808a;
  max-width: 12rem;
}
#presentation-mode #presentation-header #presentation-title .flow-name-container .flow-name-icon {
  color: #90a0b0;
  margin-bottom: 0.32rem;
  margin-left: 0.36rem;
  font-size: 0.77em;
}
#presentation-mode #presentation-header #presentation-actions .avatar {
  cursor: pointer;
  user-select: none;
}
#presentation-mode #presentation-header #presentation-actions .idea-button {
  padding-right: 0.9rem;
  padding-left: 0.75rem;
}
#presentation-mode #presentation-header #presentation-actions .idea-button i {
  font-size: 1.4em;
}
#presentation-mode #presentation-header #presentation-actions .share-button {
  padding-right: 0.85rem;
  padding-left: 0.85rem;
}
#presentation-mode #presentation-header.read-mode .back-button,
#presentation-mode #presentation-header.read-mode #presentation-actions {
  display: none !important;
}
#presentation-mode #presentation-header.read-mode .logo,
#presentation-mode #presentation-header.read-mode .logo-workix {
  margin-left: 0.7rem !important;
}
#presentation-mode #presentation-content {
  height: calc(100% - 59px);
}
#presentation-mode #presentation-sidenav {
  width: 74px;
  min-width: 74px;
  max-width: 74px;
  padding-top: 32px;
  border-right: 1px solid #e7eaf3;
  background: #2065b0;
  box-sizing: border-box;
  overflow-y: auto;
}
#presentation-mode #presentation-sidenav .chat-tab {
  width: 70px;
  height: 87px;
  color: rgba(255, 255, 255, 0.6901960784);
  padding-top: 0.25rem;
}
#presentation-mode #presentation-sidenav .chat-tab i {
  font-size: 1.6rem;
  margin-bottom: 0.54rem;
}
#presentation-mode #presentation-sidenav .chat-tab label {
  font-size: 0.8rem;
}
#presentation-mode #presentation-sidenav .chat-tab:hover,
#presentation-mode #presentation-sidenav .chat-tab.active {
  color: #fff;
}
#presentation-mode #presentation-sidenav .chat-tab.active {
  font-weight: bold;
}
#presentation-mode #presentation-chat,
#presentation-mode #presentation-sections {
  position: relative;
}
#presentation-mode #presentation-chat {
  width: 100%;
  min-width: 32rem;
}
@media (max-width: 576px) {
  #presentation-mode #presentation-chat {
    min-width: 12rem;
  }
}
#presentation-mode #presentation-sections {
  min-width: 28rem;
  border-right: 1px solid #e7eaf3;
  transition: min-width 0.4s, max-width 0.4s, width 0.4s;
  height: 100%;
  overflow-y: auto;
  background-color: white;
}
#presentation-mode #presentation-sections.data {
  width: 36rem;
  min-width: 36rem;
  max-width: 36rem;
}
#presentation-mode #presentation-sections.collapsed {
  width: 0px;
  min-width: 0px;
  max-width: 0px;
  transition: min-width 0.5s, max-width 0.5s, width 0.5s;
}
#presentation-mode #presentation-sections .tabs {
  border-bottom: 2px solid #dee2e6;
}
#presentation-mode #presentation-sections .tabs button {
  flex: 1;
  color: #777;
  background: none;
  border: 0;
  padding: 0.7rem 1rem;
  font-size: 0.8rem;
  border-bottom: 2px solid transparent;
  transition: border-bottom-color 0.3s, color 0.3s;
}
#presentation-mode #presentation-sections .tabs button.active {
  border-bottom-color: #2080e5;
}
#presentation-mode #presentation-sections .tabs button.active,
#presentation-mode #presentation-sections .tabs button:hover {
  color: #2080e5;
}
@media (max-width: 576px) {
  #presentation-mode #presentation-sections {
    position: fixed;
    left: 74px;
    z-index: 100;
    width: 18rem;
    min-width: 18rem;
    max-width: 18rem;
  }
  #presentation-mode #presentation-sections.data {
    width: 18rem;
    min-width: 18rem;
    max-width: 18rem;
  }
}
#presentation-mode #collapse-icon {
  background-color: white;
  width: 25px;
  height: 25px;
  border: 1px solid #b0b5c0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  left: -0.85rem;
  z-index: 10000;
  cursor: pointer;
}
#presentation-mode #collapse-icon:hover {
  background-color: #2080e5;
  color: white;
  border-color: white;
}

@media (max-width: 992px) {
  #presentation-mode {
    border-radius: 0rem;
  }
  #collapse-icon {
    right: 0px;
    left: 3.5rem;
  }
}
.exec-mode-tab {
  position: relative;
  color: #606570;
  font-size: 0.95rem;
  display: inline-block;
  border-bottom: 3.5px solid transparent;
  transition: border-bottom 0.2s, color 0.2s;
  border-radius: 0;
  padding: 0rem 0.4rem;
}
.exec-mode-tab.active, .exec-mode-tab:hover {
  border-bottom: 3.5px solid #2080e5;
  color: #2080e5;
  font-weight: bold;
}

#top-template #topnav header {
  background: #1a5080;
}
#top-template #topnav header #return-tab button {
  color: #fff;
  border-color: rgba(255, 255, 255, 0.5647058824);
}
#top-template #topnav header #control-tabs #edit_presentation_button {
  background: #f0b000;
  padding-right: 0.85rem;
  padding-left: 0.85rem;
}
#top-template #topnav #section-tabs {
  margin-top: 5rem;
}
#top-template #topnav .nav-badge {
  font-size: 1.6rem;
}
#top-template #topnav .nav-badge span {
  background-color: white;
  color: #132144;
  border: 1px solid #132144;
  font-weight: 100 !important;
}
#top-template #topnav .nav-badge.active span {
  background-color: #132144;
  color: white;
}
#top-template #topnav .nav-badge:hover span {
  background-color: #132144;
  color: white;
}

#side-template header#header #presentation-ctas #edit_presentation_button {
  background: #f0b000;
  padding-right: 0.85rem;
  padding-left: 0.85rem;
}
#side-template header#header #toggle-icon {
  visibility: hidden;
}

#section #collection-section {
  height: 630px;
  background-color: #fff;
}

#template-categories-container {
  padding-top: 0rem;
  width: 100%;
}
#template-categories-container .tab-button {
  background: #fff;
  color: #758085;
  transition: background 0.3s;
  border: 1px solid #ccd0d5;
  font-size: 0.96em;
  padding: 0.35rem 1rem;
  margin-right: 0.25rem;
  user-select: none;
}
#template-categories-container .tab-button i {
  display: none;
}
#template-categories-container .tab-button.active {
  background: #2080e5;
  color: #fff;
}
#template-categories-container .tab-button.active i {
  display: inline-block;
  color: #fff;
}
#template-categories-container .tab-button:hover {
  border-color: #b0bac0;
}

#template-items {
  margin-right: 0;
  margin-left: 0;
}
#template-items .flow-template {
  border-color: #ccd0d5;
  background-color: #fff;
  color: #60656a;
}
#template-items .flow-template .template-title {
  padding-right: 0;
  color: #455a65;
  font-weight: bold;
  font-size: 1em;
  line-height: 1.45;
}
#template-items .flow-template .template-title span.action-note {
  color: #aaa;
  font-size: 0.8em;
}
#template-items .flow-template .template-subtitle label {
  font-size: 0.9em;
  color: #999;
}
#template-items .flow-template .template-btn {
  width: 5rem;
}
#template-items .flow-template:hover {
  background: #fff;
}
#template-items .flow-template:hover.h5 {
  color: #20252a;
  font-weight: bold;
}
#template-items .flow-template .flow-preview {
  border: 1px solid #ccd0d5;
}

