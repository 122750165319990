

#onboarding {
    padding: 3rem 5rem;

    div.profile {
        border-radius: 10px;
        
        label.custom-checkbox-card-label {
            border: none;
        }

        .card-title {
            font-size: 2.5rem;
        }

        span.custom-checkbox-card-text::after {
            background-color: white !important;
        }
    }
}