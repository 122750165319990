#onboarding {
  padding: 3rem 5rem;
}
#onboarding div.profile {
  border-radius: 10px;
}
#onboarding div.profile label.custom-checkbox-card-label {
  border: none;
}
#onboarding div.profile .card-title {
  font-size: 2.5rem;
}
#onboarding div.profile span.custom-checkbox-card-text::after {
  background-color: white !important;
}

