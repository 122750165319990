#flow {
  width: 100% !important;
  height: 100vh;
  background-color: white;

  &.validator {
    height: 60vh !important;
  }

  #workspace {
    min-width: 64rem;

    #workspace-header {
      padding: 0.7rem 1.5rem 0.7rem 0.5rem;

      #workspace-title {

        .back-button {
          cursor: pointer;
          font-size: 1.15em;
          height: 2.3rem;
          color: #778798;
          padding-right: 0.4rem;
          padding-left: 0.5rem;

          &:hover {
            color: #2080e5;
            font-weight: bold;
          }
        }

        .logo-workix {
          cursor: pointer;
          margin-top: -0.17rem;
          width: 7rem;
          height: 2.1rem;
          margin-left: 0rem;
          background-image: url("../../../../public/theme/svg/logos/logo.svg");
          background-position: center center;
          background-repeat: no-repeat;
          background-size: 7rem 2.1rem;
        }

        .logo-sub {
          margin-top: 0.35rem;
          margin-left: 0.32rem;

          label {
            background: #2080e5;
            color: white;
            border-radius: 0.75rem;
            padding: 0.07rem 0.4rem;
          }
        }


      }


      #workspace-actions {

        .avatar {
          cursor: pointer;
          user-select: none;
        }

        .idea-button {
          padding-right: 0.9rem;
          padding-left: 0.75rem;

          i {
            font-size: 1.4em;
          }
        }

        .share-button {
          padding-right: 0.85rem;
          padding-left: 0.85rem;
        }
      }

      #workspace-switch {

        border: 1px solid #ccc;
        padding-top: 0.21rem;
        padding-bottom: 0.36rem;
        padding-left: 0.27rem;
        padding-right: 0.4rem;

        .custom-switch .custom-control-label::before {
          width: 2.5rem;
          height: 1.5rem;
          border-radius: 0.75rem;
          border-color: #f0b000 !important;
          background-color: #f0b000 !important;
        }

        .custom-switch .custom-control-label::after {
          width: 1.25rem;
          height: 1.25rem;
          border-radius: 0.75rem;
          background-color: #fff !important;
        }

        .custom-switch .custom-control-input:checked~.custom-control-label::after {
          transform: translateX(1rem);
        }

        .input-label {
          margin-left: 0.4rem;
          padding-left: 0.65rem;
          padding-right: 0.3rem;
          padding-top: 0.23rem;
          padding-bottom: 0.1rem;
          font-size: 0.8125rem;
          color: #606070;
        }
      }
    }
  }

}

/***
/ Assistant
***/

#assistant {
  position: absolute;
  bottom: 0.1rem;
  right: -0.2rem;
}

.flow-tab-padding {
  padding: 0rem 0rem;
}

@media (min-width: 992px) {
  .flow-tab-padding {
    padding: 0rem 1.45rem;
  }
}

#flow-tabs {
  margin-top: -0.4rem;

  a {
    position: relative;
    flex: 1;
    color: #70757a;
    font-size: 0.97em;
    display: inline-block;
    transition: border-bottom 0.2s, color 0.2s;
    border-radius: 0;
    padding: 0.5rem 0;
  }

  a.active,
  a:hover {
    border-bottom: 3.5px solid #2080e5;
    color: #2080e5;
    font-weight: bold;
  }

}



.nodrag:has(.dropdown-menu.show) {
  z-index: 500 !important;
}

#suggestions .prompt-wrapper .prompt-close {
  display: none;
  cursor: pointer;
}

#suggestions .prompt-wrapper:focus-within .prompt-close,
#suggestions .prompt-close:hover {
  display: block;
}

.btn-board {
  font-size: 0.84rem;
  padding: 0.45rem 0.77rem;
}

.no-editable-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(249, 249, 249, 0.5)
}


#contact-us-btn {
  position: fixed;
  right: 0%;
  bottom: 0%;
  margin-bottom: 4rem;
  margin-right: 3.2rem;
  z-index: 1000;
}