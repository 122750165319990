/*
__Seed builder__
  (Read_only) Builder helper
*/
#app {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #f0f4f6;
}

.btn-accent {
  border-color: #f0b000;
  background: #f0b000;
  color: #fff;
  transition: background 0.3s, border 0.3s, color 0.3s;
}
.btn-accent:hover {
  background: #e0a000;
  color: #fff;
}

.btn-outline-accent {
  border-color: #f0b000;
  background: #fff;
  color: #daa000;
  transition: background 0.3s, border 0.3s, color 0.3s;
}
.btn-outline-accent:hover {
  background: #f0b000;
  color: #fff;
}

.btn-tertiary {
  border-color: #184783;
  background: #184783;
  color: #fff;
  transition: background 0.3s, border 0.3s, color 0.3s;
}
.btn-tertiary:hover {
  background: #163e72;
  color: #fff;
}

.btn-outline-primary-white {
  border-color: #2080e5;
  background: white;
  color: #2080e5;
  transition: background 0.3s, border 0.3s, color 0.3s;
}
.btn-outline-primary-white:hover {
  background: #2080e5;
  color: #fff;
}
.btn-outline-primary-white.selected {
  background: #2080e5;
  color: #fff;
}
.btn-outline-primary-white.selected:hover {
  background: #2080e5;
  color: #fff;
}

.btn-outline-secondary-white {
  border-color: #71869d;
  background: white;
  color: #71869d;
  transition: background 0.3s, border 0.3s, color 0.3s;
}
.btn-outline-secondary-white:hover {
  background: #71869d;
  color: #fff;
}
.btn-outline-secondary-white.selected {
  background: #71869d;
  color: #fff;
}
.btn-outline-secondary-white.selected:hover {
  background: #71869d;
  color: #fff;
}

.btn-lighter {
  border-color: #ccc;
  background: transparent;
  color: #707070;
  transition: background 0.3s, border 0.3s, color 0.3s;
}
.btn-lighter:hover {
  background: #f5f5f5;
}
.btn-lighter.selected {
  border-color: #f0b000;
  background: #f0b000;
  color: #fff;
}
.btn-lighter.selected:hover {
  background: #eaa500;
}

.btn-lighter-white {
  border-color: #ccc;
  background: #fff;
  color: #707070;
  transition: background 0.3s, border 0.3s, color 0.3s;
}
.btn-lighter-white:hover {
  background: #f5f5f5;
}
.btn-lighter-white.selected {
  border-color: #f0b000;
  background: #f0b000;
  color: #fff;
}
.btn-lighter-white.selected:hover {
  background: #eaa500;
}

.btn-grey {
  border: 0;
  background: #999;
  color: #fff;
  transition: background 0.3s, border 0.3s, color 0.3s;
}
.btn-grey:hover {
  background: #777;
  color: #fff;
}
.btn-grey.selected {
  border-color: #f0b000;
  background: #f0b000;
  color: #fff;
}
.btn-grey.selected:hover {
  background: #eaa500;
  color: #fff;
}

ul.step li.step-item:last-child span.step-icon::after {
  display: none !important;
}

div.custom-control-right .custom-control-label {
  margin-left: -1.5rem;
}

div.custom-control-right .custom-control-label::before,
div.custom-control-right .custom-control-label::after {
  right: -1.5rem;
  left: initial;
}

.dropdown-toggle::after {
  display: none !important;
}

@keyframes background-anim {
  from {
    background-position: -100px 0;
  }
  to {
    background-position: -200px -140px;
  }
}
.animated-background {
  background-color: #1a5595;
  animation-name: background-anim;
  animation-duration: 25s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
  animation-direction: alternate;
  background-size: 120% auto;
  background-position: -100px 0;
}

.scrollbar-narrow::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}

.scrollbar-narrow::-webkit-scrollbar-track {
  background-color: transparent;
}

.scrollbar-narrow::-webkit-scrollbar-thumb {
  background-color: #d6dae1;
  border-radius: 10px;
  border: 0px solid transparent;
  background-clip: content-box;
}

.scrollbar-narrow::-webkit-scrollbar-thumb:hover {
  background-color: #a8b2bf;
}

input.MuiInputBase-input {
  width: 100% !important;
}

.react-flow__node-node:has(.has-warnings) {
  z-index: 997 !important;
}

.react-flow__node-node:has(.has-errors) {
  z-index: 998 !important;
}

.react-flow__node-node:has(.has-execute-message) {
  z-index: 999 !important;
}

.react-flow__node-pipe {
  z-index: -1 !important;
}

.react-flow__edgeupdater {
  cursor: crosshair !important;
}

.react-flow__resize-control.handle {
  background: rgba(0, 0, 0, 0) !important;
  border: none !important;
}

.MuiPopover-paper {
  overflow-y: hidden !important;
}

