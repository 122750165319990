#toolbox-container {
  position: relative;
  width: 414px;
  min-width: 414px;
  max-width: 414px;
  background: #f9faff;
  border-top: 1px solid #dae5f0;
  border-right: 1px solid #d2dae0;
  transition: min-width 0.3s, max-width 0.3s, width 0.3s;
}
#toolbox-container.absolute {
  width: 332px !important;
  min-width: 332px !important;
  max-width: 332px !important;
}
#toolbox-container.collapsed {
  width: 82px !important;
  min-width: 82px !important;
  max-width: 82px !important;
  transition: min-width 0.25s, max-width 0.25s, width 0.25s;
}
#toolbox-container.data-section {
  width: 750px;
  min-width: 750px;
  max-width: 750px;
  transition: min-width 0.4s, max-width 0.4s, width 0.4s;
}
#toolbox-container.data-section #toolbox-main {
  width: 668px;
  min-width: 668px;
  max-width: 668px;
}
#toolbox-container #toolbox-sidenav {
  width: 82px;
  min-width: 82px;
  max-width: 82px;
  padding-top: 24px;
  border-right: 1px solid #dae5f0;
  background: #fff;
  box-sizing: border-box;
  overflow-y: auto;
}
#toolbox-container #toolbox-sidenav .btn-app {
  width: 70px;
  height: 85px;
  color: #708090;
  padding-top: 0.25rem;
}
#toolbox-container #toolbox-sidenav .btn-app i {
  font-size: 1.68rem;
  margin-bottom: 0.57rem;
}
#toolbox-container #toolbox-sidenav .btn-app label {
  font-size: 0.8rem;
}
#toolbox-container #toolbox-sidenav .btn-app:hover,
#toolbox-container #toolbox-sidenav .btn-app.active {
  color: #2080E5;
}
#toolbox-container #toolbox-sidenav .btn-app.active {
  font-weight: bold;
}
#toolbox-container #toolbox-main {
  height: calc(100% - 68px);
  width: 332px;
  min-width: 332px;
  max-width: 332px;
}
#toolbox-container #toolbox-main #toolbox-actions {
  height: calc(100% - 74px);
  padding-top: 1.4rem;
  padding-bottom: 1.7rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  overflow-y: auto;
  scrollbar-gutter: stable both-edges;
}
#toolbox-container #toolbox-main #toolbox-actions.validator {
  height: 100% !important;
}
#toolbox-container #toolbox-main #toolbox-actions #toolbox-search {
  padding-bottom: 1.35rem;
}
#toolbox-container #toolbox-main #toolbox-actions #toolbox-search input {
  border: 1px solid #ccd0d5;
  font-size: 0.97em;
  transition: 0.4s;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  padding-left: 2.25rem;
  padding-right: 2rem;
  background: #fff;
}
#toolbox-container #toolbox-main #toolbox-actions #toolbox-search input:focus {
  background: #fff;
}
#toolbox-container #toolbox-main #toolbox-actions #toolbox-search .search-icon {
  font-size: 0.9rem;
  margin-top: -1.55rem;
  margin-left: 0.75rem;
}
#toolbox-container #toolbox-main #toolbox-actions #toolbox-search .search-cta {
  position: relative;
  left: 100%;
  width: 3.5rem;
  margin-left: -3.77rem;
  margin-top: -0.9rem;
}
#toolbox-container #toolbox-main #toolbox-actions #toolbox-search .search-cta .search-close,
#toolbox-container #toolbox-main #toolbox-actions #toolbox-search .search-cta .search-filters {
  cursor: pointer;
  font-size: 0.91rem;
  color: #90959a;
}
#toolbox-container #toolbox-main #toolbox-actions #toolbox-search .search-cta .search-close:hover,
#toolbox-container #toolbox-main #toolbox-actions #toolbox-search .search-cta .search-filters:hover {
  color: #70757a;
}
#toolbox-container #toolbox-main #toolbox-actions #toolbox-search .search-cta .search-filters.active {
  color: #2080e5;
}
#toolbox-container #toolbox-main #toolbox-actions #toolbox-search .search-cta .search-close {
  display: none;
}
#toolbox-container #toolbox-main #toolbox-actions #toolbox-search .search-cta .search-close:hover {
  display: block;
}
#toolbox-container #toolbox-main #toolbox-actions #toolbox-search:focus-within i.search-close {
  display: block;
}
#toolbox-container #toolbox-main #toolbox-actions #toolbox-tabs {
  padding-top: 1.2rem;
  width: 100%;
}
#toolbox-container #toolbox-main #toolbox-actions #toolbox-tabs .tab-button {
  background: #fff;
  color: #758085;
  transition: background 0.3s;
  border: 1px solid #ccd0d5;
  font-size: 0.96em;
  padding: 0.35rem 1rem;
  margin-right: 0.25rem;
  user-select: none;
}
#toolbox-container #toolbox-main #toolbox-actions #toolbox-tabs .tab-button i {
  display: none;
}
#toolbox-container #toolbox-main #toolbox-actions #toolbox-tabs .tab-button.active {
  background: #2080e5;
  color: #fff;
}
#toolbox-container #toolbox-main #toolbox-actions #toolbox-tabs .tab-button.active i {
  display: inline-block;
  color: #fff;
}
#toolbox-container #toolbox-main #toolbox-actions #toolbox-tabs .tab-button:hover {
  border-color: #b0bac0;
}
#toolbox-container #toolbox-main #toolbox-actions .suggestion-divider {
  width: 100%;
  border-top: 1px solid #ddd;
  margin-top: 0.25rem;
  margin-bottom: 0.7rem;
}
#toolbox-container #toolbox-main #toolbox-actions #toolbox-suggestions {
  background: #fffafa;
}
#toolbox-container #toolbox-main #toolbox-actions #toolbox-suggestions .dropdown-header span {
  color: #777;
}
#toolbox-container #toolbox-main #toolbox-actions #toolbox-suggestions .dropdown-header span i {
  color: #f0b000;
}
#toolbox-container #toolbox-main #toolbox-actions #toolbox-suggestions ul.list-group {
  border: none;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
}
#toolbox-container #toolbox-main #toolbox-actions div.collapse {
  position: relative;
  z-index: 5;
  background: #f9faff;
}
#toolbox-container #toolbox-main #toolbox-actions div.collapse-divider {
  padding-top: 0rem;
  padding-bottom: 0.25rem;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}
#toolbox-container #toolbox-main #toolbox-actions div.collapse-divider label {
  font-size: 0.95em;
  font-style: italic;
  color: #70757a;
}
#toolbox-container #toolbox-main #toolbox-actions .action-element .btn-action {
  cursor: pointer;
  border-color: #ccd0d5;
  background-color: #fff;
  color: #60656a;
}
#toolbox-container #toolbox-main #toolbox-actions .action-element .btn-action:hover {
  background: #fff;
  transform: translate(2px, -2px);
}
#toolbox-container #toolbox-main #toolbox-actions .action-element .btn-action:hover.h5 {
  color: #20252a;
  font-weight: bold;
}
#toolbox-container #toolbox-main #toolbox-actions .action-element .btn-action .app-icon {
  margin-left: 0.3rem;
  margin-right: 1rem;
}
#toolbox-container #toolbox-main #toolbox-actions .action-element .btn-action .action-title {
  padding-right: 0;
  color: #455a65;
  font-weight: bold;
  font-size: 0.965em;
  line-height: 1.45;
}
#toolbox-container #toolbox-main #toolbox-actions .action-element .btn-action .action-title span.action-note {
  color: #aaa;
  font-size: 0.8em;
}
#toolbox-container #toolbox-main #toolbox-actions .action-element .btn-action .action-subtitle label {
  font-size: 0.9em;
  color: #999;
}
#toolbox-container #toolbox-main #toolbox-actions .action-element .btn-action .action-trigger-label {
  color: #a0a5b0;
  font-size: 0.87em;
  font-weight: normal;
  margin-left: 0.3rem;
}
#toolbox-container #toolbox-main #toolbox-actions span.search-title {
  color: #555;
}
#toolbox-container #toolbox-main #toolbox-actions ul.actions-list div.hidden-actions a.hidden-actions-title {
  border: none;
  color: #a0a0a0;
  font-size: 0.9em;
  margin-left: -0.75rem;
}
#toolbox-container #toolbox-main #toolbox-actions ul.actions-list div.hidden-actions a.hidden-actions-title i {
  color: #b4b4b4;
  font-size: 0.9em;
}
#toolbox-container #toolbox-main #toolbox-actions .section-title {
  margin-top: 0.65rem;
  margin-bottom: 0.7rem;
}
#toolbox-container #toolbox-main #toolbox-actions .section-title label {
  font-size: 0.97em;
  color: #70757a;
}
#toolbox-container #toolbox-main #toolbox-actions .apps {
  width: 48%;
  margin-bottom: 0.75rem;
}
#toolbox-container #toolbox-main #toolbox-actions .apps.first-col {
  margin-right: 4%;
}
#toolbox-container #toolbox-main #toolbox-actions .apps.suggestions {
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 0rem;
}
#toolbox-container #toolbox-main #toolbox-actions .apps.suggestions .btn-category {
  padding-top: 0.8rem;
  padding-bottom: 0.4rem;
}
#toolbox-container #toolbox-main #toolbox-actions .apps.suggestions .category-icon {
  font-size: 1.6em;
}
#toolbox-container #toolbox-main #toolbox-actions .apps.suggestions .category-title {
  margin-top: 0.25rem;
}
#toolbox-container #toolbox-main #toolbox-actions .apps .btn-category {
  border: 1px solid #ccd0d5;
  font-size: 0.9em;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  padding-top: 1.4rem;
  padding-bottom: 1rem;
  background: #fff;
  color: #758085;
  transition: background 0.3s, border-color 0.3s;
  font-size: 0.97em;
  margin-right: 0.25rem;
  height: 100%;
}
#toolbox-container #toolbox-main #toolbox-actions .apps .btn-category i {
  display: none;
  color: #959aa0;
}
#toolbox-container #toolbox-main #toolbox-actions .apps .btn-category[aria-expanded=true] {
  background: #2080e5 !important;
}
#toolbox-container #toolbox-main #toolbox-actions .apps .btn-category[aria-expanded=true] .category-icon {
  color: #fff !important;
}
#toolbox-container #toolbox-main #toolbox-actions .apps .btn-category[aria-expanded=true] .category-title {
  color: #fff !important;
}
#toolbox-container #toolbox-main #toolbox-actions .apps .btn-category:hover {
  background: #fff;
  border-color: #a5aab0;
}
#toolbox-container #toolbox-main #toolbox-actions .apps .btn-category:hover .category-icon {
  color: #777;
}
#toolbox-container #toolbox-main #toolbox-actions .apps .btn-category:hover .category-title {
  color: #222;
}
#toolbox-container #toolbox-main #toolbox-actions .apps .category-icon {
  color: #80858a;
  font-size: 1.8em;
  text-align: center;
  width: 100%;
}
#toolbox-container #toolbox-main #toolbox-actions .apps .category-title {
  color: #454a50;
  font-size: 0.98em;
  margin-top: 0.8rem;
  text-align: center;
}
#toolbox-container #toolbox-main #toolbox-actions .apps .category-title span {
  line-height: 1.15;
  display: inline-block;
  vertical-align: top;
}
#toolbox-container #toolbox-main #toolbox-actions .no-results {
  position: absolute;
  font-size: 0.9em;
  margin-top: 5.2rem;
  z-index: 0;
}
#toolbox-container #toolbox-main #toolbox-templates {
  height: 100%;
  padding-top: 1.4rem;
  padding-bottom: 1.7rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  overflow-y: auto;
  scrollbar-gutter: stable both-edges;
}
#toolbox-container #toolbox-main #toolbox-templates div.section-title {
  margin-top: 0.6rem;
  margin-bottom: 0.7rem;
}
#toolbox-container #toolbox-main #toolbox-templates div.section-title label {
  font-size: 1em;
  color: #70757a;
}
#toolbox-container #toolbox-main #toolbox-templates #toolbox-search {
  padding-bottom: 1.35rem;
}
#toolbox-container #toolbox-main #toolbox-templates #toolbox-categories {
  width: 100%;
}
#toolbox-container #toolbox-main #toolbox-templates #toolbox-categories .tab-button {
  background: #fff;
  color: #758085;
  transition: background 0.3s;
  border: 1px solid #ccd0d5;
  font-size: 0.96em;
  padding: 0.35rem 1rem;
  margin-right: 0.25rem;
  user-select: none;
}
#toolbox-container #toolbox-main #toolbox-templates #toolbox-categories .tab-button i {
  display: none;
}
#toolbox-container #toolbox-main #toolbox-templates #toolbox-categories .tab-button.active {
  background: #2080e5;
  color: #fff;
}
#toolbox-container #toolbox-main #toolbox-templates #toolbox-categories .tab-button.active i {
  display: inline-block;
  color: #fff;
}
#toolbox-container #toolbox-main #toolbox-templates #toolbox-categories .tab-button:hover {
  border-color: #b0bac0;
}
#toolbox-container #toolbox-main #toolbox-templates #toolbox-templates-items {
  margin-right: 0;
  margin-left: 0;
}
#toolbox-container #toolbox-main #toolbox-templates .flow-sample {
  border-color: #ccd0d5;
  background-color: #fff;
  color: #60656a;
}
#toolbox-container #toolbox-main #toolbox-templates .flow-sample .action-title {
  padding-right: 0;
  color: #455a65;
  font-weight: bold;
  font-size: 0.95em;
  line-height: 1.45;
}
#toolbox-container #toolbox-main #toolbox-templates .flow-sample .action-title span.action-note {
  color: #aaa;
  font-size: 0.8em;
}
#toolbox-container #toolbox-main #toolbox-templates .flow-sample .action-subtitle label {
  font-size: 0.9em;
  color: #999;
}
#toolbox-container #toolbox-main #toolbox-templates .flow-sample:hover {
  background: #fff;
  transform: translate(2px, -2px);
}
#toolbox-container #toolbox-main #toolbox-templates .flow-sample:hover.h5 {
  color: #20252a;
  font-weight: bold;
}
#toolbox-container #toolbox-main #toolbox-templates .flow-sample .flow-preview {
  border: 1px solid #ccd0d5;
}
#toolbox-container #toolbox-main #toolbox-guides {
  padding-top: 1.2rem;
  padding-bottom: 1.7rem;
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}
#toolbox-container #collapse-toolbox {
  background-color: white;
  width: 25px;
  height: 25px;
  border: 1px solid #b0b5c0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  right: -12px;
  z-index: 1000;
  cursor: pointer;
}
#toolbox-container #collapse-toolbox:hover {
  background-color: #2080e5;
  color: white;
  border-color: white;
}
#toolbox-container .more-actions-wrapper {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}
#toolbox-container .more-actions-wrapper .more-actions-divider {
  width: 100%;
  border-top: 1px solid #ddd;
  margin-bottom: 1.25rem;
}

#app-store {
  height: 530px;
  overflow: hidden;
}
#app-store #search-bar input {
  border: 1px solid #d0deda;
}
#app-store #search-bar i {
  font-size: 1rem;
  margin-top: -1.5rem;
}
#app-store #toolbox-apps-tab {
  border-bottom: 2px solid #dee2e6;
}
#app-store #toolbox-apps-tab button {
  flex: 1;
  color: #777;
  background: none;
  border: 0;
  border-bottom: 2px solid transparent;
  transition: border-bottom-color 0.3s, color 0.3s;
}
#app-store #toolbox-apps-tab button.active {
  border-bottom-color: #2080e5;
}
#app-store #toolbox-apps-tab button.active,
#app-store #toolbox-apps-tab button:hover {
  color: #2080e5;
}
#app-store #app-list {
  height: 400px;
  overflow-y: auto;
}
#app-store #app-list ul .app-element .apps-group {
  background: #f9faff;
  font-size: 0.9em;
  left: 1.1rem;
  color: #757a80;
}
#app-store #app-list ul .app-element li.app-item {
  padding-left: 1.4rem;
}
#app-store #app-list ul .app-element li.app-item .app-icon i {
  font-size: 1.1rem;
}
#app-store #app-list ul .app-element li.app-item .app-title .app-label {
  font-size: 0.82rem;
  color: #40454a;
}
#app-store #app-list ul .app-element li.app-item div.app-actions button.app-request-access {
  width: 4.5rem;
  line-height: 1.25;
}
#app-store #app-list ul .app-element li.app-item div.app-actions button.app-install {
  width: 4.5rem;
  line-height: 1.25;
}
#app-store #app-list ul .app-element li.app-item div.app-actions div.app-uninstall button {
  border: none;
  background-color: white;
}
#app-store #app-list ul .app-element li.app-item div.app-actions div.app-uninstall button i {
  color: #777;
}
#app-store #app-list ul .app-element li.app-item div.app-actions button.app-requested {
  width: 4.5rem;
  line-height: 1.25;
  opacity: 1;
}

