#start {
  height: 100vh;
  width: 100%;
  overflow-y: auto;

  #start-container {
    min-height: 900px;

    #start-logo {
      height: 2.6rem;
      margin-top: 0.4rem;
      margin-bottom: 0.7rem;
    }

    @media (max-width: 576px) {
      #start-logo {
        height: 2.4rem;
      }
    }

    #start-profile {
      background-color: #fff;
      border: 1px solid #d2dae0 !important;
      border-radius: 0.36rem;
      box-shadow: 0 6px 12px rgba(140, 152, 164, .075);
      padding: 0.3rem 0.8rem;

      &:hover {
        background-color: #fff !important;
      }
    }

    #start-panel {
      border: 1px solid #d2dae0;

      aside {
        border-radius: 10px;

        #start-panel-title {
          border-bottom: 0.12rem solid #e7eaf3;
          background: #f7faff;
          border-radius: 10px 10px 0px 0px;

          .app-logo {
            padding-left: 1.25rem;
          }

          #account-settings {
            #accountNavbarDropdown {
              width: 16rem;
              top: 4rem;

              .dropdown-item {
                cursor: pointer;
              }
            }
          }
        }

        #start-panel-folders {
          ul.folders-list {
            display: block;
          }

          .create-folder-button {
            cursor: pointer;
            font-size: 0.97em;
          }
        }
      }
    }

    #pending-executions {
      border: 1px solid #d2dae0;

      #executions-container {
        max-height: 9.8rem;
        min-height: 1.29rem;
        overflow-y: auto;
      }
    }

    #start-templates {
      border: 1px solid #d2dae0;
      overflow: hidden;

      #background-hero {
        height: 100%;
        width: 100%;
      }

      #start-templates-container {
        padding: 2.85rem 1.5rem 2.25rem 1.5rem;

        #start-templates-title {
          color: #405060;
        }

        #start-templates-search {
          #start-templates-search-input {
            width: 75%;

            input {
              border: 1px solid #dae0e5;
              background: white;
            }
          }

          #start-templates-search-button {
            width: 25%;
            min-width: 5.6rem;
          }
        }
      }
    }

    #start-flows-list {

      .nav-sub {
        margin-left: 2.4rem;

        .nav-link {
          border-left: 2px solid #eaf0f5;
          padding-left: 1.5rem;
        }
      }
    }

    #start-flows {
      background-color: #f7fafe;
      border: 1px solid #d2dae0;

      #start-flows-container {

        .card {
          border: 1px solid #d2dae0;
        }

        #flows {
          #flows-container {
            margin-top: 0.75rem;



            .flow {
              width: 32.1%;
              min-width: 32.1%;
              margin-right: 0.8rem;
              margin-bottom: 0.8rem;

              &.large {
                width: 48.85%;
                min-width: 48.85%;

                &:nth-child(2n) {
                  margin-right: 0rem;
                }
              }

              &:hover .h4 {
                color: #2080e5 !important;
                transition: color 0.4s;
              }

              .flow-settings {

                .flow-settings-content{
                  top: 2.75rem !important;
                  left: 3.75rem !important;
                }
                button {
                  font-size: 1.2em;
                  border: none;

                  i {
                    color: #909090;
                  }
                }
              }
            }

            @media (min-width: 1200px) {
              .flow {            
                &:nth-child(3n) {
                  margin-right: 0rem;
                }
              }
            }            

            @media (max-width: 1200px) {
              .flow {
                width: 48%;
                min-width: 48%;

                &:nth-child(2n) {
                  margin-right: 0rem;
                }
              }
            }

            @media (max-width: 576px) {
              .flow {
                width: 99.2%;
                min-width: 99.2%;

                &:nth-child(1n) {
                  margin-right: 0rem;
                }
              }
            }

           
          }

          #see-more-flows {
            border-color: rgba(231, 234, 243, 0.7);
          }
        }

        #no-flows {
          #no-flows-title {
            color: #708090;
            font-size: 1.05rem;
            margin-bottom: 1.25rem;
          }

          a {
            i {
              font-size: 1em;
            }
          }
        }
      }
    }

    #heatmap-usage {
      background-color: #f7fafe;
      border: 1px solid #d2dae0;

      #heatmap-usage-container {
        padding: 1.5rem 0.7rem 0rem 0.7rem;

        #heatmap-usage-title {
          color: #505560;
          font-size: 1.04rem;
          padding: 0rem 0.7rem;
        }
      }
    }

    #recent-executions {
      border: 1px solid #d2dae0;

      #recent-executions-container {
        padding: 1.5rem 1.5rem 1.2rem 1.5rem;

        #recent-executions-title {
          color: #808590;
          font-size: 0.95em;
          margin-bottom: 0.7rem;
        }
      }
    }

    #executions-list {
      .no-data-legend {
        span {
          color: #b0b2b2;
          font-size: 0.95em;
        }
      }
    }
  }

  .back-button-flow {
    display: block;
    width: 2.15rem;
    height: 2.15rem;
    background-image: url("../../../../public/theme/svg/logos/logo-short.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 2.15rem;
    cursor: default;
  }

  .navbar-vertical-content .nav-link {
    color: #6a7080 !important;
  }

  .nav-link.active i, .nav-link.active span,
  .nav-link:hover i, .nav-link:hover span {
    color: #2080e5;
  }
}






#start-nav {
  aside {
    border-radius: 10px;
  }

  #start-nav-header {
    border-bottom: 0.12rem solid #e7eaf3;
    background: #f7fafe;
    border-radius: 10px 10px 0px 0px;

    #accountNavbarDropdown {
      width: 16rem;
      top: 4rem;
    }
  }

  #start-icon {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    margin-left: 1.15rem;
    background-image: url("../../../../public/theme/svg/logos/logo-short.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 2.4rem;
    cursor: default;
  }
}

#billing {
  #billing-container {
    border: none;
    box-shadow: none;

    #plan-details {
      .name {
        font-weight: normal;
      }
    }
  }
}

#flows {
  #flow-list {}

  #no-flows {
    font-size: 1rem;

    i {
      font-size: 2.7rem;
      color: #5a606a;
    }
  }
}

#external-apps {
  #external-apps-body {
    #integration-accounts {
      .integration-account {
        .integration-settings {
          button {
            font-size: 1em;
            border: none;

            i {
              color: #909090;
            }
          }
        }
      }
    }
  }
}