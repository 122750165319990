$component-border: #ccd0d5;
$component-bg: #fff;
$sidenav-width: 74px;

#presentation-mode {
  width: auto;
  height: 100%;
  background: #ECF4FF;
  overflow-y: auto;

  #presentation-header {
    padding: 0.65rem 1.25rem 0.65rem 0.7rem;
    background: white;
    border-bottom: 1px solid #e7eaf3;

    #presentation-title {

      .back-button {
        cursor: pointer;
        font-size: 1.15em;
        height: 2.3rem;
        color: #778798;
        padding-right: 0.4rem;
        padding-left: 0.6rem;

        &:hover {
          color: #2080e5;
          font-weight: bold;
        }
      }

      .logo {
        cursor: pointer;
        margin-top: -0.17rem;
        margin-left: 0rem;
        height: 2.1rem;
      }

      .logo-workix {
        cursor: pointer;
        margin-top: -0.17rem;
        width: 7rem;
        height: 2.1rem;
        margin-left: 0rem;
        background-image: url("../../../../public/theme/svg/logos/logo.svg");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 7rem 2.1rem;
      }

      .dropdown{
        margin-left: 0.1rem;
      }

      .flow-name-container {
        height: 1.85rem;

        .flow-name {
          color: #77808a;
          max-width: 12rem;
        }

        .flow-name-icon {
          color: #90a0b0;
          margin-bottom: 0.32rem;
          margin-left: 0.36rem;
          font-size: 0.77em;
        }
      }


    }


    #presentation-actions {

      .avatar {
        cursor: pointer;
        user-select: none;
      }

      .idea-button {
        padding-right: 0.9rem;
        padding-left: 0.75rem;

        i {
          font-size: 1.4em;
        }
      }

      .share-button {
        padding-right: 0.85rem;
        padding-left: 0.85rem;
      }
    }

    &.read-mode {

      .back-button,
      #presentation-actions {
        display: none !important;
      }

      .logo,
      .logo-workix {
        margin-left: 0.7rem !important;
      }
    }
  }

  #presentation-content {
    height: calc(100% - 59px);
  }

  #presentation-sidenav {
    width: $sidenav-width;
    min-width: $sidenav-width;
    max-width: $sidenav-width;
    padding-top: 32px;
    border-right: 1px solid #e7eaf3;
    background: #2065b0;
    box-sizing: border-box;
    overflow-y: auto;

    .chat-tab {
      width: 70px;
      height: 87px;
      color: #ffffffb0;
      padding-top: 0.25rem;

      i {
        font-size: 1.6rem;
        margin-bottom: 0.54rem;
      }

      label {
        font-size: 0.8rem;
      }
    }

    .chat-tab:hover,
    .chat-tab.active {
      color: #fff;
    }

    .chat-tab.active {
      font-weight: bold;
    }
  }

  #presentation-chat,
  #presentation-sections {
    position: relative;
  }

  #presentation-chat {
    width: 100%;
    min-width: 32rem;
  }

  @media (max-width: 576px) {
    #presentation-chat {
      min-width: 12rem;
    }
  }

  #presentation-sections {
    min-width: 28rem;
    border-right: 1px solid #e7eaf3;
    transition: min-width 0.4s, max-width 0.4s, width 0.4s;
    height: 100%;
    overflow-y: auto;
    background-color: white;

    &.data {
      width: 36rem;
      min-width: 36rem;
      max-width: 36rem;
    }

    &.collapsed {
      width: 0px;
      min-width: 0px;
      max-width: 0px;
      transition: min-width 0.5s, max-width 0.5s, width 0.5s;
    }

    .tabs {
      border-bottom: 2px solid #dee2e6;
  
      button {
        flex: 1;
        color: #777;
        background: none;
        border: 0;
        padding: 0.7rem 1rem;
        font-size: 0.8rem;
        border-bottom: 2px solid transparent;
        transition: border-bottom-color 0.3s, color 0.3s;
  
        &.active {
          border-bottom-color: #2080e5;
        }
      }
  
      button.active,
      button:hover {
        color: #2080e5;
      }
    }    
  }

  @media (max-width: 576px) {
    #presentation-sections{
      position: fixed;
      left: $sidenav-width;
      z-index: 100;
      width: 18rem;
      min-width: 18rem;
      max-width: 18rem;
      &.data {
        width: 18rem;
        min-width: 18rem;
        max-width: 18rem;
      }
    }    
  }

  #collapse-icon {
    background-color: white;
    width: 25px;
    height: 25px;
    border: 1px solid #b0b5c0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10px;
    left: -0.85rem;
    z-index: 10000;
    cursor: pointer;

    &:hover {
      background-color: #2080e5;
      color: white;
      border-color: white;
    }
  }
}

@media (max-width: 992px) {
  #presentation-mode {
    border-radius: 0rem;
  }

  #collapse-icon {
    right: 0px;
    left: 3.5rem;
  }
}

.exec-mode-tab {
  position: relative;
  color: #606570;
  font-size: 0.95rem;
  display: inline-block;
  border-bottom: 3.5px solid transparent;
  transition: border-bottom 0.2s, color 0.2s;
  border-radius: 0;
  padding: 0rem 0.4rem;

  &.active,
  &:hover {
    border-bottom: 3.5px solid #2080e5;
    color: #2080e5;
    font-weight: bold;
  }
}


#top-template {
  #topnav header {
    background: #1a5080;

    #return-tab button {
      color: #fff;
      border-color: #ffffff90;
    }

    #control-tabs {
      #edit_presentation_button {
        background: #f0b000;
        padding-right: 0.85rem;
        padding-left: 0.85rem;
      }
    }
  }

  #topnav #section-tabs {
    margin-top: 5rem;
  }

  #topnav {
    .nav-badge {
      font-size: 1.6rem;
    }

    .nav-badge span {
      background-color: white;
      color: #132144;
      border: 1px solid #132144;
      font-weight: 100 !important;
    }

    .nav-badge.active span {
      background-color: #132144;
      color: white;
    }

    .nav-badge:hover span {
      background-color: #132144;
      color: white;
    }
  }
}

#side-template {
  header#header {
    #presentation-ctas #edit_presentation_button {
      background: #f0b000;
      padding-right: 0.85rem;
      padding-left: 0.85rem;
    }

    #toggle-icon {
      visibility: hidden;
    }
  }
}

#section {
  #collection-section {
    height: 630px;
    background-color: #fff;
  }
}

#template-categories-container {
  padding-top: 0rem;
  width: 100%;

  .tab-button {
    background: $component-bg;
    color: #758085;
    transition: background 0.3s;
    border: 1px solid $component-border;
    font-size: 0.96em;
    padding: 0.35rem 1rem;
    margin-right: 0.25rem;
    user-select: none;

    i {
      display: none;
    }

    &.active {
      background: #2080e5;
      color: #fff;

      i {
        display: inline-block;
        color: #fff;
      }
    }

    &:hover {
      border-color: #b0bac0;
    }
  }
}

#template-items {
  margin-right: 0;
  margin-left: 0;

  .flow-template {
    border-color: $component-border;
    background-color: $component-bg;
    color: #60656a;

    .template-title {
      padding-right: 0;
      color: #455a65;
      font-weight: bold;
      font-size: 1em;
      line-height: 1.45;

      span.action-note {
        color: #aaa;
        font-size: 0.8em;
      }
    }

    .template-subtitle {
      label {
        font-size: 0.9em;
        color: #999;
      }
    }

    .template-btn {
      width: 5rem;
    }

    &:hover {
      background: #fff;

      &.h5 {
        color: #20252a;
        font-weight: bold;
      }
    }

    .flow-preview {
      border: 1px solid $component-border;
    }
  }
}