@import url("https://fonts.googleapis.com/css?family=Red+Hat+Display:400,500,900&display=swap");

$background-color: #ECF4FF;
$user-background: #2080E5;
$bot-background: #fff;
$action-color: #2080E5;
$suggestion-background: #FFF;
$suggestion-border: #2080E5;
$chat-width: 56rem;

#chat {
  height: 100%;
  width: 100%;
  background-color: $background-color;

  .chat-squares {
    position: absolute;
    width: 100%;
    max-width: 85rem;
    bottom: 0px;
    right: 0px;
    height: 24rem;
    overflow: hidden;
    pointer-events: none;
  }

  #chat-content {
    height: 100%;
    width: 100%;
    overflow-y: auto;
    padding-bottom: 7rem;

    .chat-messages {
      padding: 1.5rem 0.5rem 7rem 0.5rem;
      max-width: $chat-width;
      margin: auto;
    }

    .chat-dark {
      background-color: #333;
    }
  }

  .message {
    box-sizing: border-box;
    padding: 0.5rem 1rem;
    margin-bottom: 0.75rem;
    background: $bot-background;
    border-radius: 1.125rem 1.125rem 1.125rem 0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.05), 0rem 1rem 1rem -1rem rgba(0, 0, 0, 0.05);
    color: #444;
    font-size: 0.95rem;
    overflow-x: auto;
    line-height: 1.65;

    p {
      margin-bottom: 0.2rem;
    }
  }

  .message.user {
    border-radius: 1.125rem 1.125rem 0 1.125rem;
    background: $user-background;
    color: white;
  }

  .message.suggestion {
    border-radius: 1.125rem 1.125rem 0 1.125rem;
    background: $suggestion-background;
    border: 1px solid $suggestion-border;
    color: $action-color;
    margin-bottom: 0rem;


    &:hover {
      background: #f8fafb;
    }
  }

  .message.inner-toast {
    .loading-icon{
      color: #2080E5
    }
  }

  #no-messages {
    position: absolute;
    text-align: center;
    left: 50%;
    top: 50%;
    width: 18rem;
    margin-left: -9rem;
    margin-top: -11.7rem;

    .icon {
      font-size: 3rem;
      margin-bottom: 1.5rem;
      color: #8095b0;
    }

    .content {

      h3,
      label {
        color: #8095b0;
      }

      h3{
        display: inline-block;
        margin-bottom: 0.15rem;
      }

      label {
        font-size: 0.96rem;
        line-height: 1.5;
      }
    }
  }

  #chat-footer {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    pointer-events: none;

    .suggestion-container {
      width: 100%;
      max-width: $chat-width;
      margin: auto;
      padding: 0.25rem 0.35rem;


      .suggestion-wrapper {
        pointer-events: auto;
        border-radius: 1.5rem 1.5rem 0 1.5rem;
        padding: 0px;
        border-top: 4px solid $background-color;
        border-right: 5px solid $background-color;
        border-left: 5px solid $background-color;
        border-bottom: 4px solid $background-color;

        .message.suggestion {
          padding: 0.45rem 0.95rem;         
        }
      }
    }

    .input-container {
      width: 100%;
      max-width: $chat-width;
      margin: auto;
      padding: 0.5rem 1.2rem 1.5rem 1.2rem;
      background-color: #ECF4FF;
      pointer-events: auto;

      input {
        bottom: 0px;
        width: 100%;
        border: none;
        border: 1px solid #eee;
        padding: 11px 40px 11px 23px;
        box-shadow: 0 0 1rem rgba(0, 0, 0, 0.05), 0rem 1rem 1rem -1rem rgba(0, 0, 0, 0.05);
        font-size: 15.2px;
        border-radius: 1.5rem;
        transition: box-shadow 0.3s, border 0.3s;

        &::placeholder {
          color: #8090a0;
          opacity: 1;
        }

        &:focus {
          border: 1px solid #2080E500;
          box-shadow: 0 0 0.25rem #2080E5b0;
          transition: box-shadow 0.4s, border 0.4s;
        }
        &:hover {
          border: 1px solid #2080E5a0;
          box-shadow: 0 0 0.25rem #2080E5b0;
          transition: box-shadow 0.4s, border 0.4s;
        }
      }

      .send {
        position: relative;
        display: block;
        margin-left: calc(100% - 61px);
        margin-top: -44px;
        padding: 8px 14px;
        cursor: pointer;
        font-size: 25px;
        color: $action-color;
        border: none;
        background: none;
        transition: color 0.4s;

        &:hover {
          color: $action-color;
        }

        &:disabled {
          color: #90a0aa !important;
          transition: color 0.4s;
        }
      }
    }
  }

}

.loading {
  margin: 0rem 1rem;
  margin-top: -2rem;

  .loader {
    height: 80px;
    aspect-ratio: 1;
    display: grid;
  }

  .loader:before,
  .loader:after {
    content: "";
    --c: no-repeat linear-gradient(#2080E5 0 0);
    background: var(--c), var(--c);
    background-size: 25% 50%;
    animation: l4 1.1s infinite linear;
  }

  .loader:after {
    transform: scale(-1);
  }

  @keyframes l4 {

    0%,
    10% {
      background-position: 33.4% 100%, 66.6% 100%
    }

    40% {
      background-position: 33.4% 0, 100% 100%
    }

    70% {
      background-position: 0 100%, 66.6% 0
    }

    100% {
      background-position: 33.4% 100%, 66.6% 100%
    }
  }
}